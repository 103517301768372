// Generated by Framer (9a49031)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {I9mbsOE69: {hover: true}};

const cycleOrder = ["I9mbsOE69", "Aq4FSWq56"];

const serializationHash = "framer-8fhXi"

const variantClassNames = {Aq4FSWq56: "framer-v-smwkav", I9mbsOE69: "framer-v-5j1ed"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {close: "Aq4FSWq56", open: "I9mbsOE69"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "I9mbsOE69"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I9mbsOE69", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapbx0883 = activeVariantCallback(async (...args) => {
setVariant("Aq4FSWq56")
})

const onTapw52bzr = activeVariantCallback(async (...args) => {
setVariant("I9mbsOE69")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "Aq4FSWq56") return false
return true
}

const isDisplayed1 = () => {
if (baseVariant === "Aq4FSWq56") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-8fhXi", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-5j1ed", className)} data-framer-name={"open"} data-highlight layoutDependency={layoutDependency} layoutId={"I9mbsOE69"} onTap={onTapbx0883} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"I9mbsOE69-hover": {"data-framer-name": undefined}, Aq4FSWq56: {"data-framer-name": "close", onTap: onTapw52bzr}}, baseVariant, gestureVariant)}><motion.div className={"framer-xrd3w7"} layoutDependency={layoutDependency} layoutId={"jhKly94l6"}>{isDisplayed() && (<SVG className={"framer-10ur906"} data-framer-name={"circle"} fill={"rgba(0,0,0,1)"} intrinsicHeight={42} intrinsicWidth={42} layoutDependency={layoutDependency} layoutId={"PxpZKrofg"} svg={"<svg width=\"42\" height=\"42\" viewBox=\"0 0 42 42\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M14 24H28\" stroke=\"#0B0C0E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<path d=\"M14 18H28\" stroke=\"#0B0C0E\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n<rect x=\"0.5\" y=\"0.5\" width=\"41\" height=\"41\" rx=\"20.5\" stroke=\"#0B0C0E\" stroke-opacity=\"0.3\"/>\n</svg>\n"} withExternalLayout/>)}</motion.div>{isDisplayed1() && (<SVG className={"framer-10lowd8"} data-framer-name={"Frame"} fill={"rgba(0,0,0,1)"} intrinsicHeight={41} intrinsicWidth={40} layoutDependency={layoutDependency} layoutId={"kfzkFCRAD"} svg={"<svg width=\"40\" height=\"41\" viewBox=\"0 0 40 41\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M20 40.035C30.7696 40.035 39.5 31.3046 39.5 20.535C39.5 9.76545 30.7696 1.035 20 1.035C9.23045 1.035 0.5 9.76545 0.5 20.535C0.5 31.3046 9.23045 40.035 20 40.035Z\" stroke=\"black\" stroke-opacity=\"0.3\"/>\n<path d=\"M13.3535 14.1814L26.3535 27.1814\" stroke=\"black\"/>\n<path d=\"M26.6465 14.1814L13.6465 27.1815\" stroke=\"black\"/>\n</svg>\n"} withExternalLayout/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-8fhXi [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8fhXi .framer-r7z8t { display: block; }", ".framer-8fhXi .framer-5j1ed { cursor: pointer; height: 42px; overflow: visible; position: relative; width: 42px; }", ".framer-8fhXi .framer-xrd3w7 { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-8fhXi .framer-10ur906 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-8fhXi .framer-10lowd8 { flex: none; height: 41px; left: 0px; position: absolute; top: 0px; width: 40px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Aq4FSWq56":{"layout":["fixed","fixed"]},"IfoTJHvxX":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerHjK8RXrvY: React.ComponentType<Props> = withCSS(Component, css, "framer-8fhXi") as typeof Component;
export default FramerHjK8RXrvY;

FramerHjK8RXrvY.displayName = "Menu Icon";

FramerHjK8RXrvY.defaultProps = {height: 42, width: 42};

addPropertyControls(FramerHjK8RXrvY, {variant: {options: ["I9mbsOE69", "Aq4FSWq56"], optionTitles: ["open", "close"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerHjK8RXrvY, [])